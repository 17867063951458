import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">
      <path d="M5070 7963 c-8 -4 -143 -77 -300 -163 -651 -359 -616 -337 -709 -431
-69 -71 -142 -202 -160 -291 -7 -33 -10 -148 -9 -300 l3 -248 102 56 101 56 4
206 c3 200 4 209 30 264 35 76 72 123 125 159 37 26 374 215 781 438 136 75
172 107 172 158 0 71 -74 121 -140 96z"/>
<path d="M5600 7723 c-8 -3 -89 -47 -180 -98 -91 -51 -336 -188 -545 -305
-209 -117 -402 -226 -428 -242 -81 -52 -101 -125 -48 -179 53 -52 59 -50 486
189 215 120 479 267 589 328 229 126 256 148 256 206 0 32 -7 47 -34 74 -33
33 -60 40 -96 27z"/>
<path d="M5850 7324 c-135 -75 -510 -285 -835 -467 -324 -182 -698 -391 -830
-465 -132 -74 -252 -146 -267 -160 -70 -65 -29 -182 63 -182 12 0 189 94 393
209 204 114 526 295 716 401 190 106 435 243 545 305 110 62 280 156 378 211
102 56 184 108 194 123 8 13 16 43 16 65 0 32 -6 45 -32 68 -53 47 -71 42
-341 -108z"/>
<path d="M6197 6974 c-52 -27 -522 -288 -857 -476 -113 -63 -401 -224 -640
-358 -715 -399 -680 -376 -680 -442 0 -68 54 -116 112 -100 25 6 414 220 1088
597 234 131 571 319 750 418 179 99 337 191 352 205 34 32 43 71 25 113 -28
68 -77 82 -150 43z"/>
<path d="M6246 6479 c-50 -27 -96 -55 -103 -61 -10 -8 -13 -58 -13 -209 0
-227 -9 -269 -81 -359 -52 -66 -76 -81 -549 -344 -223 -123 -420 -237 -439
-253 -28 -24 -34 -36 -38 -80 -5 -45 -2 -55 20 -77 37 -36 70 -42 114 -18 157
82 897 501 935 528 76 56 155 149 192 227 58 120 66 171 66 450 0 136 -3 247
-7 247 -5 -1 -48 -23 -97 -51z"/>
<path d="M5735 6176 c-16 -7 -136 -73 -265 -146 -208 -117 -394 -221 -790
-443 -151 -84 -184 -131 -145 -206 17 -33 65 -56 99 -47 46 11 1181 647 1203
674 43 51 39 125 -9 155 -37 24 -61 27 -93 13z"/>
<path d="M7612 5389 c-44 -13 -108 -79 -121 -126 -16 -55 -14 -81 10 -130 40
-83 137 -129 221 -104 120 36 178 150 129 256 -43 94 -135 134 -239 104z"/>
<path d="M2835 4754 c-131 -22 -243 -72 -320 -145 -130 -122 -160 -332 -75
-506 58 -116 149 -171 448 -269 271 -88 313 -118 320 -230 10 -149 -100 -225
-308 -211 -133 8 -264 62 -377 154 l-41 34 -72 -103 -71 -103 43 -38 c57 -51
224 -132 323 -158 99 -27 328 -32 415 -10 209 52 329 175 360 368 22 136 -10
259 -92 348 -66 72 -178 127 -388 191 -241 73 -289 98 -321 168 -24 52 -24
110 0 161 22 49 43 68 106 97 42 19 67 22 155 22 120 -1 220 -28 323 -90 32
-19 62 -34 67 -34 8 0 130 186 130 199 0 9 -112 74 -164 95 -87 36 -202 58
-316 61 -63 2 -128 1 -145 -1z"/>
<path d="M7534 4747 c-3 -8 -4 -86 -2 -173 3 -151 4 -159 23 -156 46 8 126 45
169 78 64 49 89 102 90 189 l1 70 -138 3 c-111 2 -138 0 -143 -11z"/>
<path d="M4205 4193 c-277 -621 -445 -1003 -445 -1009 0 -3 62 -3 137 -2 l138
3 81 185 c44 102 118 271 164 375 45 105 125 286 178 403 l95 214 14 -34 c67
-153 223 -533 223 -539 0 -5 -101 -9 -225 -9 -256 0 -244 5 -201 -85 28 -60
91 -120 143 -135 21 -6 118 -14 215 -17 l177 -6 51 -121 c29 -67 63 -147 77
-178 l26 -58 137 0 c75 0 139 3 143 6 3 4 -2 23 -12 43 -10 20 -66 146 -124
281 -286 665 -518 1204 -528 1221 -9 17 -22 19 -113 19 l-103 0 -248 -557z"/>
<path d="M5700 4522 c0 -126 3 -479 7 -786 l6 -557 426 3 c414 4 428 5 496 27
99 33 153 62 212 116 59 52 106 137 124 223 16 77 6 203 -21 274 -23 62 -103
159 -151 184 l-29 14 37 48 c49 64 72 120 82 197 23 196 -56 355 -215 430
-107 51 -148 55 -576 55 l-398 0 0 -228z m815 -31 c77 -36 112 -103 102 -198
-7 -64 -29 -105 -77 -144 -43 -35 -111 -49 -229 -49 l-87 0 -42 -40 c-23 -23
-62 -71 -87 -108 l-45 -67 247 -5 c162 -4 258 -10 277 -18 91 -39 125 -97 126
-210 0 -105 -33 -162 -120 -208 -31 -17 -65 -19 -322 -22 l-288 -3 0 545 0
546 253 0 c224 0 257 -3 292 -19z"/>
<path d="M7540 3785 l0 -605 135 0 135 0 0 605 0 605 -135 0 -135 0 0 -605z"/>
<path d="M3262 2769 c-116 -23 -187 -117 -186 -249 2 -141 93 -230 236 -230
82 0 125 13 171 51 59 49 82 101 82 189 0 83 -20 133 -72 182 -53 50 -148 74
-231 57z m139 -75 c119 -60 118 -267 -1 -329 -41 -21 -123 -19 -168 4 -49 25
-74 70 -79 145 -4 50 0 69 19 105 46 90 140 120 229 75z"/>
<path d="M2410 2535 l0 -235 35 0 35 0 0 163 0 162 76 -127 c42 -71 83 -128
90 -128 7 0 47 57 89 128 l75 127 0 -161 c0 -88 2 -163 5 -166 3 -2 18 -3 35
0 l30 4 0 235 0 234 -32 -3 c-29 -3 -38 -14 -110 -138 -44 -74 -83 -139 -87
-143 -5 -5 -39 45 -77 110 -96 167 -100 173 -135 173 l-29 0 0 -235z"/>
<path d="M3755 2765 c-6 -3 -9 -90 -8 -237 l2 -233 128 0 c104 1 135 4 167 20
75 36 100 125 50 184 -14 17 -32 31 -40 31 -19 0 -18 5 10 33 34 34 43 90 23
133 -26 53 -65 67 -202 70 -66 2 -124 2 -130 -1z m239 -76 c19 -15 26 -30 26
-54 0 -54 -36 -75 -126 -75 l-74 0 0 75 0 75 74 0 c59 0 78 -4 100 -21z m16
-204 c26 -13 30 -21 30 -55 0 -58 -38 -80 -142 -80 l-78 0 0 75 0 75 80 0 c53
0 91 -5 110 -15z"/>
<path d="M4317 2764 c-4 -4 -7 -110 -7 -236 l0 -228 40 0 40 0 -2 232 -3 232
-31 3 c-16 2 -33 1 -37 -3z"/>
<path d="M4610 2536 l0 -233 74 -6 c40 -3 110 -3 155 0 80 6 82 7 79 30 -3 25
-4 25 -118 27 l-115 1 -3 208 -2 207 -35 0 -35 0 0 -234z"/>
<path d="M5075 2758 c-3 -8 -4 -115 -3 -238 l3 -225 165 0 165 0 3 28 3 27
-130 0 -131 0 0 75 0 75 110 0 110 0 0 30 0 30 -110 0 -110 0 0 75 0 75 126 0
125 0 -3 28 -3 27 -158 3 c-122 2 -159 0 -162 -10z"/>
<path d="M5810 2530 l0 -243 128 5 c138 5 185 19 236 66 44 42 66 98 66 174 0
80 -8 104 -52 154 -53 60 -101 76 -250 82 l-128 5 0 -243z m233 169 c91 -25
143 -121 118 -215 -24 -90 -76 -124 -198 -132 l-83 -5 0 182 0 181 63 0 c34 0
79 -5 100 -11z"/>
<path d="M6522 2678 c-22 -51 -68 -156 -101 -232 -34 -76 -61 -142 -61 -146 0
-4 10 -6 23 -4 12 1 29 3 37 3 9 1 25 26 38 61 l24 61 110 -3 110 -3 23 -55
c22 -53 25 -55 65 -58 23 -2 40 0 37 5 -2 4 -48 110 -103 236 l-98 227 -32 0
c-31 0 -33 -4 -72 -92z m75 -206 c-44 -2 -81 -1 -84 1 -4 4 17 60 57 152 l22
50 42 -100 43 -100 -80 -3z"/>
<path d="M6870 2740 l0 -30 80 0 80 0 0 -205 0 -205 40 0 40 0 0 205 0 205 75
0 75 0 0 30 0 30 -195 0 -195 0 0 -30z"/>
<path d="M7505 2638 c-104 -237 -144 -337 -137 -341 4 -2 14 -3 22 -2 8 1 22
3 31 3 10 1 25 25 39 62 l22 60 110 0 109 0 26 -60 c25 -59 27 -60 64 -60 l39
0 -14 33 c-70 166 -181 414 -189 425 -5 6 -22 12 -37 12 -26 0 -31 -9 -85
-132z m133 -68 l40 -95 -80 -3 c-44 -2 -82 -1 -84 1 -2 2 10 37 27 78 17 41
35 85 40 98 5 13 11 22 13 20 2 -2 22 -47 44 -99z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
